.profileImage {
    vertical-align: sub;
    object-fit: cover;
    width: 55px;
    height: 55px;
    margin: 2px 10px 2px 0;
    border-radius: 50%;
}

#qrcode {
    padding-bottom: 5px;
}

#referral-link {
    padding: 0.5em;
    background: #eeeeee;
    border-radius: 0.25rem;
    box-shadow: inset 0 0 0 2px #dcdcdc;
    overflow-wrap: break-word;
}
.cpy {
    float: left;
    color: #29797f !important;
}
.share {
    float: right;
    color: #29797f !important;
}

#shareModal {
    z-index: 12345;
}

.shareButton {
    background-color: #ededed;
    border: 0.1px solid #ededed !important;
    color: #444444 !important;
    border-radius: 34px 34px 8px 34px;
    box-shadow: 0 5px 4px 0 rgba(0, 0, 0, 0.26);
    height: 60px;
    min-width: 60px;
    position: fixed;
    right: 20px;
    bottom: 90px;
    z-index: 999;
    text-decoration: none;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow:hidden;
    width: auto;
}

.share-button-icon {
    padding-top: 10px;
}

.chat_button {
    z-index: 999 !important;
}
